'use client';

import { useTranslation } from '@/i18n/client';
import { useParams } from 'next/navigation';
import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Locale } from '../../../../i18n-config';
import Image from 'next/image';
// import AppService from '../services/appService'

const LKSectionFive = () => {
  const params = useParams();
  const { t } = useTranslation(params?.lang as Locale);

  const [stats] = useState<{
    total_shortened_links: number;
    total_customers: number;
    total_clicks: number;
  }>({
    total_customers: 10000,
    total_clicks: 18000000,
    total_shortened_links: 730000,
  });

  // const [isLoading, setIsLoading] = useState(false);

  // const fetchStats = useCallback(
  //   async () => {
  //     try {
  //       setIsLoading(true);

  //       const { data: res } = await AppService.statistics();

  //       setStats(res.element);

  //       setIsLoading(false);
  //     } catch (error) {
  //       setIsLoading(false);

  //       //console.log(error)
  //     }
  //   },
  //   [],
  // )

  return (
    <section className="section-5">
      <Container>
        <Row className="justify-content-center">
          <Col lg={10} md={10} xs={12}>
            <div className="lk-stats rounded-pill">
              <Row>
                <Col
                  xs={4}
                  className="mb-md-0 mb-3 d-flex flex-column align-items-center justify-content-center text-center"
                >
                  <div className="iconic-i mb-2">
                    <Image
                      className="img-fluid"
                      width={0}
                      height={0}
                      src="https://cdn.pinkswan.ch/application/LUMBERJACK/2208240635_link.png"
                      alt="L-K.io"
                      title="link"
                      style={{ width: 20, height: 20 }}
                    />
                  </div>
                  <div className="desc">
                    <p className="mb-1">{t('LKSectionFive/key2')}</p>
                    <span>
                      {new Intl.NumberFormat('en-US', { notation: 'compact' }).format(stats.total_shortened_links)}
                    </span>
                  </div>
                </Col>
                <Col
                  xs={4}
                  className="mb-md-0 mb-3 d-flex flex-column align-items-center justify-content-center text-center"
                >
                  <div className="iconic-i mb-2">
                    <Image
                      className="img-fluid"
                      width={0}
                      height={0}
                      src="https://cdn.pinkswan.ch/application/LUMBERJACK/2208240638_person.png"
                      alt="L-K.io"
                      title="person"
                      style={{ width: 20, height: 20 }}
                    />
                  </div>
                  <div className="desc">
                    <p className="mb-1">{t('LKSectionFive/key3')}</p>
                    <span>{new Intl.NumberFormat('en-US', { notation: 'compact' }).format(stats.total_customers)}</span>
                  </div>
                </Col>
                <Col
                  xs={4}
                  className="mb-md-0 mb-3 d-flex flex-column align-items-center justify-content-center text-center"
                >
                  <div className="iconic-i mb-2">
                    <Image
                      className="img-fluid"
                      width={0}
                      height={0}
                      title="mouse"
                      src="https://cdn.pinkswan.ch/application/LUMBERJACK/2208240638_mouse.png"
                      alt="L-K.io"
                      style={{ width: 16, height: 18 }}
                    />
                  </div>
                  <div className="desc">
                    <p className="mb-1">{t('LKSectionFive/key1')}</p>
                    <span>{new Intl.NumberFormat('en-US', { notation: 'compact' }).format(stats?.total_clicks)}</span>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default LKSectionFive;
