'use client';

import { useEffect, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import { BiLockAlt } from 'react-icons/bi';
import { useWindowSize } from '../../../hooks/useWindowSize';
import Link from 'next/link';
import { useTranslation } from '@/i18n/client';
import { useParams } from 'next/navigation';
import { Locale } from '../../../../i18n-config';
import Image from 'next/image';

const LKSectionOne = () => {
  const params = useParams();
  const { t } = useTranslation(params?.lang as Locale);
  const [width] = useWindowSize();

  const [linkWidth, setLinkWidth] = useState(0);
  const linkRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (null !== linkRef.current) {
      setLinkWidth(linkRef.current.offsetWidth);
    }
  }, []);

  const calcTrunc = (str: string) => {
    if (width >= 1110) return str;
    else if (width < 1110 && width >= 667) return str.substring(0, 50) + '...';
    else if (width <= 667 && width > 375) return str.substring(0, 25) + '...';
    else if (width <= 375) return str.substring(0, 20) + '...';
  };

  return (
    <section className="d-flex section-1 py-lg-5 py-3">
      <Container fluid={'xl'} className="my-auto">
        <h1 className="fw-bold lk-text-red">
          {t('LKSectionOne/key5')}
          <br />
          {t('LKSectionOne/key3')}
        </h1>
        <div className="position-relative my-4 lk-url-wrap">
          <ul className="lk-url-circles d-lg-flex d-none">
            <li></li>
            <li></li>
            <li></li>
          </ul>
          <ul className="lk-url-arrows">
            <li className="d-md-block d-none">
              <Image
                title="l-k.io"
                src="https://cdn.pinkswan.ch/application/LUMBERJACK/2208240624_arrow-left.svg"
                alt="Lumberjack-arrow-left"
                width={11}
                height={22}
              />
            </li>
            <li className="d-md-block d-none">
              <Image
                title="l-k.io"
                src="https://cdn.pinkswan.ch/application/LUMBERJACK/2208240624_arrow-right.svg"
                alt="Lumberjack-arrow-right"
                width={11}
                height={22}
              />
            </li>
            <li className="d-lg-block d-none">
              <Image
                title="l-k.io"
                src="https://cdn.pinkswan.ch/application/LUMBERJACK/2208240624_split.svg"
                alt="Lumberjack-split"
                width={31}
                height={31}
              />
            </li>
          </ul>
          <div className="lk-url-path">
            <div className="bullet">
              <BiLockAlt size={18} />
            </div>
            <a href="https://l-k.io/BDZZG5" className="url" target="_blank" rel="noreferrer">
              <span ref={linkRef}>https://l-k.io/BDZZG5</span>
              <span className="prevent-select">
                {calcTrunc('https://www.jungle.com/jungle-fashion/b/?ie=UTF8&node=71411230')}
              </span>
            </a>
            <div className="character" style={{ left: linkWidth + 80 }}>
              <Image
                title="l-k.io lumberjack pushing long link"
                src="https://cdn.pinkswan.ch/application/LUMBERJACK/23122408085569_pushing.webp"
                alt="L-K.io"
                width={256}
                height={319}
                placeholder="empty"
                priority
                // data-aos="fade-left"
              />
            </div>
          </div>
        </div>
        <h2 className="fw-bold lk-text-black">{t('LKSectionOne/key1')}</h2>
        <h2 className="fw-bold lk-text-black mb-3">{t('LKSectionOne/key4')}</h2>
        <Link href={`/home/${params?.lang}/plans`} className="lk-red-button fs-5 p-3">
          {t('LKSectionOne/key2')}
        </Link>
      </Container>
    </section>
  );
};

export default LKSectionOne;
