'use client';

import { useState } from 'react';
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { BsChevronLeft, BsChevronRight, BsArrowRight } from 'react-icons/bs';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useParams } from 'next/navigation';
import { useTranslation } from '@/i18n/client';
import { Locale } from '../../../../i18n-config';
import Link from 'next/link';
import Image from 'next/image';

const LKSectionFour = () => {
  const params = useParams();
  const { t } = useTranslation(params?.lang as Locale);

  const [my_swiper, set_my_swiper] = useState<any>({});
  const [position, setPosition] = useState({
    isEnd: false,
    isBegining: true,
  });

  return (
    <section className="d-flex flex-column section-4" id="features">
      <Container fluid={'lg'} className="my-auto">
        <div className="text-center my-5">
          <Image
            src="https://cdn.pinkswan.ch/application/LUMBERJACK/2208240633_features_1.svg"
            className="img-fluid img-1 d-lg-none d-block mx-auto mb-3"
            alt="L-K.io"
            width={0}
            height={0}
            title="features_1"
            style={{ width: 200, height: 200 }}
            // data-aos="fade-down"
          />
          <h2 className="fs-1 lk-text-red mb-4">{t('LKSectionFour/key22')}</h2>
          <p style={{ fontSize: 14, color: '#54575E' }} className="w-50 m-auto">
            {t('LKSectionFour/key20')} <b className="lk-text-red">+40</b> {t('LKSectionFour/key32')}
          </p>
        </div>

        <div className="animates">
          <Image
            src="https://cdn.pinkswan.ch/application/LUMBERJACK/2208240633_features_1.svg"
            className="img-fluid img-1"
            alt="L-K.io"
            width={0}
            height={0}
            title="features_1"
            style={{ width: 200, height: 200 }}
            // data-aos="fade-up-right"
          />
          <Image
            src="https://cdn.pinkswan.ch/application/LUMBERJACK/2208240633_email_reports.svg"
            className="img-fluid img-2"
            alt="L-K.io"
            width={0}
            height={0}
            title="email_reports"
            style={{ width: 200, height: 200 }}
            // data-aos="fade-up-left"
          />
        </div>
        <div className="lk-tab position-relative">
          <Tabs defaultActiveKey="unlimited_clicks" id="tab-example" className="mb-5">
            <Tab eventKey="unlimited_clicks" title={t('LKSectionFour/key27')}>
              <div className="lk-quota-card">
                <div className="title">
                  <div className="iconic-i">
                    <Image
                      src="https://cdn.pinkswan.ch/application/LUMBERJACK/24021109371153_Link_icon.png"
                      className="img-fluid"
                      width={30}
                      height={30}
                      title="link"
                      alt="L-K.io"
                    />
                  </div>
                  <h3>{t('LKSectionFour/key26')}</h3>
                </div>
                <div className="content">
                  <p>
                    {t('LKSectionFour/key4')}
                    {t('LKSectionFour/key34')} <br />
                    {t('LKSectionFour/key6')}
                  </p>
                </div>
              </div>
            </Tab>
            <Tab eventKey="advanced_analytics" title={t('LKSectionFour/key3')}>
              <div className="lk-quota-card">
                <div className="title">
                  <div className="iconic-i">
                    <Image
                      src="https://cdn.pinkswan.ch/application/LUMBERJACK/2208240633_chart.png"
                      alt="L-K.io"
                      width={0}
                      height={0}
                      title="chart"
                      style={{ width: 40, height: 40 }}
                    />
                  </div>
                  <h3>{t('LKSectionFour/key2')}</h3>
                </div>
                <div className="content">
                  <p>
                    {t('LKSectionFour/key5')}
                    <br />
                  </p>
                  {/* <ul className='ps-3'>
                    <li>Report on the country of origin of the visits</li>
                    <li>Report on redirection targeting based on OS</li>
                    <li>Report on redirection targeting based on country</li>
                    <li>Report on referred visits</li>
                  </ul> */}
                </div>
              </div>
            </Tab>
            <Tab eventKey="link_tracker" title={t('LKSectionFour/key19')}>
              <div className="lk-quota-card">
                <div className="title">
                  <div className="iconic-i">
                    <Image
                      src="https://cdn.pinkswan.ch/application/LUMBERJACK/2208240633_linkTracker.png"
                      alt="L-K.io"
                      width={0}
                      height={0}
                      title="link tracker"
                      style={{ width: 30, height: 30 }}
                    />
                  </div>
                  <h3>{t('LKSectionFour/key18')}</h3>
                </div>
                <div className="content">
                  <p>{t('LKSectionFour/key17')}</p>
                </div>
              </div>
            </Tab>
            <Tab eventKey="email_report" title={t('LKSectionFour/key14')}>
              <div className="lk-quota-card">
                <div className="title">
                  <div className="iconic-i">
                    <Image
                      src="https://cdn.pinkswan.ch/application/LUMBERJACK/2208240633_emailReport.png"
                      alt="L-K.io"
                      width={0}
                      height={0}
                      title="email report"
                      style={{ width: 30, height: 30 }}
                    />
                  </div>
                  <h3>{t('LKSectionFour/key13')}</h3>
                </div>
                <div className="content">
                  <p>
                    {t('LKSectionFour/key16')}
                    <br />
                    {t('LKSectionFour/key30')}
                  </p>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
        <div className="animates_2">
          <Image
            src="https://cdn.pinkswan.ch/application/LUMBERJACK/2208240633_Ax.svg"
            className="img-fluid img-1"
            alt="L-K.io"
            width={0}
            height={0}
            title="ax"
            style={{ width: 200, height: 200 }}
            // data-aos="fade-down-right"
          />
          <Image
            src="https://cdn.pinkswan.ch/application/LUMBERJACK/2208240633_Backsaw_1.svg"
            className="img-fluid img-2"
            alt="L-K.io"
            width={0}
            height={0}
            title="backsaw"
            style={{ width: 200, height: 200 }}
            // data-aos="fade-down-left"
          />
        </div>

        <Row className="mt-5 g-3">
          <Col lg={3}>
            <h2 className="lk-text-red">{t('LKSectionFour/key1')}</h2>
            <p className="fw-light lk-text-black">{t('LKSectionFour/key7')}</p>
            <Link href={`/home/${params?.lang}/plans`} className="btn btn-outline-danger rounded-pill">
              {t('LKSectionFour/key28')}
            </Link>
          </Col>
          <Col lg={9}>
            <Swiper
              spaceBetween={20}
              slidesPerView={1.2}
              breakpoints={{
                640: {
                  slidesPerView: 1,
                  spaceBetween: 0,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 50,
                },
              }}
              navigation={false}
              modules={[Navigation]}
              onSlideChange={() => {
                if (my_swiper.isEnd) {
                  setPosition({
                    isEnd: true,
                    isBegining: false,
                  });
                } else if (my_swiper.isBeginning) {
                  setPosition({
                    isEnd: false,
                    isBegining: true,
                  });
                } else {
                  setPosition({
                    isEnd: false,
                    isBegining: false,
                  });
                }
              }}
              onInit={(ev) => {
                set_my_swiper(ev);
              }}
            >
              <SwiperSlide>
                <div className="lk-feature-card" data-value="1">
                  <div className="num">
                    <p className="fs-4">
                      <u>01</u>
                    </p>
                  </div>
                  <p className="fs-4">{t('LKSectionFour/key26')}</p>
                  <div className="desc">
                    <p>{t('LKSectionFour/key31')}</p>
                  </div>
                  {!position.isEnd && (
                    <div className="d-flex justify-content-end mt-auto">
                      <BsArrowRight size={30} onClick={() => my_swiper.slideNext()} />
                    </div>
                  )}
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="lk-feature-card" data-value="2">
                  <div className="num">
                    <p className="fs-4">
                      <u>02</u>
                    </p>
                  </div>
                  <p className="fs-4">{t('LKSectionFour/key15')}</p>
                  <div className="desc">
                    <p>Lumberjack {t('LKSectionFour/key33')}</p>
                  </div>
                  {!position.isEnd && (
                    <div className="d-flex justify-content-end mt-auto">
                      <BsArrowRight size={30} onClick={() => my_swiper.slideNext()} />
                    </div>
                  )}
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="lk-feature-card" data-value="3">
                  <div className="num">
                    <p className="fs-4">
                      <u>03</u>
                    </p>
                  </div>
                  <p className="fs-4">{t('LKSectionFour/key25')}</p>
                  <div className="desc">
                    <p>{t('LKSectionFour/key29')}</p>
                  </div>
                  {!position.isEnd && (
                    <div className="d-flex justify-content-end mt-auto">
                      <BsArrowRight size={30} onClick={() => my_swiper.slideNext()} />
                    </div>
                  )}
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="lk-feature-card" data-value="4">
                  <div className="num">
                    <p className="fs-4">
                      <u>04</u>
                    </p>
                  </div>
                  <p className="fs-4">{t('LKSectionFour/key8')}</p>
                  <div className="desc">
                    <p>{t('LKSectionFour/key11')}</p>
                  </div>
                  {!position.isEnd && (
                    <div className="d-flex justify-content-end mt-auto">
                      <BsArrowRight size={30} onClick={() => my_swiper.slideNext()} />
                    </div>
                  )}
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="lk-feature-card" data-value="5">
                  <div className="num">
                    <p className="fs-4">
                      <u>05</u>
                    </p>
                  </div>
                  <p className="fs-4">{t('LKSectionFour/key12')}</p>
                  <div className="desc">
                    <p>{t('LKSectionFour/key10')}</p>
                  </div>
                  {!position.isEnd && (
                    <div className="d-flex justify-content-end mt-auto">
                      <BsArrowRight size={30} onClick={() => my_swiper.slideNext()} />
                    </div>
                  )}
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="lk-feature-card" data-value="6">
                  <div className="num">
                    <p className="fs-4">
                      <u>06</u>
                    </p>
                  </div>
                  <p className="fs-4">{t('LKSectionFour/key9')}</p>
                  <div className="desc">
                    <p>{t('LKSectionFour/key23')}</p>
                  </div>
                  {!position.isEnd && (
                    <div className="d-flex justify-content-end mt-auto">
                      <BsArrowRight size={30} onClick={() => my_swiper.slideNext()} />
                    </div>
                  )}
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="lk-feature-card" data-value="7">
                  <div className="num">
                    <p className="fs-4">
                      <u>07</u>
                    </p>
                  </div>
                  <p className="fs-4">{t('LKSectionFour/key21')}</p>
                  <div className="desc">
                    <p>{t('LKSectionFour/key24')}</p>
                  </div>
                  {!position.isEnd && (
                    <div className="d-flex justify-content-end mt-auto">
                      <BsArrowRight size={30} onClick={() => my_swiper.slideNext()} />
                    </div>
                  )}
                </div>
              </SwiperSlide>
            </Swiper>
            <div className="lk-offers-nav d-flex align-items-center justify-content-end my-4">
              <button
                className="btn btn-sm btn-link fs-5"
                disabled={position.isBegining}
                onClick={() => my_swiper.slidePrev()}
                role="button"
                aria-label="previous slide"
              >
                <BsChevronLeft />
              </button>
              <button
                className="btn btn-sm btn-link fs-5"
                disabled={position.isEnd}
                onClick={() => my_swiper.slideNext()}
                role="button"
                aria-label="next slide"
              >
                <BsChevronRight />
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default LKSectionFour;
